import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.css']
})
export class AccessoriesComponent implements OnInit {

    componentBannerTitle = "Accessories";
    componentBannerImageURL = "";
    componentBannerClasses = "page-banner banner-light product-banner";
  
    stripTitle = "Interested in our services now?";
    stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
    buttonTitle = "Contact Us";
    buttonLink = "/contact#contact-form";
    stripBg = "bg-blue";

  constructor() { }

  ngOnInit() {
  }

}
