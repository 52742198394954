import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  componentBannerTitle = "Products";
  componentBannerImageURL = "../../../assets/images/products-banner.jpg";
  componentBannerClasses = "page-banner banner-light";

  stripTitle = "Interested in our services now?";
  stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
  buttonTitle = "Contact Us";
  buttonLink = "/contact#contact-form";
  stripBg = "bg-dark";

  constructor() { }

  ngOnInit() {
  }

}
