import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-strip',
  templateUrl: './contact-strip.component.html',
  styleUrls: ['./contact-strip.component.css']
})
export class ContactStripComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input('stripTitle') stripTitle: string;
  @Input('stripContent') stripContent: string;
  @Input('buttonTitle') buttonTitle: string;
  @Input('buttonLink') buttonLink: string;
  @Input('stripBg') stripBg: string; 
}
