import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs'; // only need to import from rxjs
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { CountToModule } from 'angular-count-to';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BannerComponent } from './common/banner/banner.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContactStripComponent } from './common/contact-strip/contact-strip.component';
import { SocialLinksStripComponent } from './common/social-links-strip/social-links-strip.component';
import { ServicesComponent } from './pages/services/services.component';
import { SparePartsComponent } from './pages/spare-parts/spare-parts.component';
import { CDCCComponent } from './products/cdcc/cdcc.component';
import { DryersComponent } from './products/dryers/dryers.component';
import { CoolersComponent } from './products/coolers/coolers.component';
import { ConditionersComponent } from './products/conditioners/conditioners.component';
import { AccessoriesComponent } from './products/accessories/accessories.component';



const appRoutes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'spare-parts', component: SparePartsComponent },
  { path: 'products/CDCC', component: CDCCComponent },
  { path: 'products/dryers', component: DryersComponent },
  { path: 'products/coolers', component: CoolersComponent },
  { path: 'products/conditioners', component: ConditionersComponent },
  { path: 'products/accessories', component: AccessoriesComponent },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    PageNotFoundComponent,
    BannerComponent,
    ProductsComponent,
    ContactStripComponent,
    SocialLinksStripComponent,
    ServicesComponent,
    SparePartsComponent,
    CDCCComponent,
    DryersComponent,
    CoolersComponent,
    ConditionersComponent,
    AccessoriesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    NgbModule.forRoot(),
    AnimateOnScrollModule.forRoot(),
    CountToModule
  ],
  providers: [AnimateOnScrollModule],
  bootstrap: [AppComponent],
  // exports: [ContactComponent]
})
export class AppModule {


}
