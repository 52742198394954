import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isNavbarCollapsed = true;
    }
  }

  isNavbarCollapsed: boolean;

  toggleMenu() {
    this.isNavbarCollapsed = true;
    window.scrollTo(0, 0);
  }
  constructor(public router: Router, public eRef: ElementRef) {
    this.isNavbarCollapsed = true;

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.toggleMenu();
      }
    });
  }



  ngOnInit() {
  }

}
