import { Component, OnInit, ViewChild } from '@angular/core';

import { BannerComponent } from '../../common/banner/banner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
/// <reference types="googlemaps" />

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;


  componentBannerTitle = "Contact";
  titleSpan = "Us";
  componentBannerImageURL = "../../../assets/images/contact-banner.jpg";
  componentBannerClasses = "page-banner banner-dark text-center";

  messageSuccess = false;
  showLoader = false;

  contactForm = {
    name: "",
    email: "",
    subject: "",
    message: "",
    recipient: "vspase"
  };
  google: any;
  constructor(public http: HttpClient) { }


  initMap() {

    var styles = new google.maps.StyledMapType(
      [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8ec3b9"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1a3646"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#64779e"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#334e87"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6f9ba5"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#3C7680"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#304a7d"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2c6675"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#255763"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#b0d5ce"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#3a4762"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#0e1626"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4e6d70"
            }
          ]
        }
      ]
    );


    var mapProp = {
      center: { lat: 18.5114187, lng: 73.7748058 },
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map.mapTypes.set('styled_map', styles);
    this.map.setMapTypeId('styled_map');

    var marker = new google.maps.Marker({
      position: { lat: 18.5114187, lng: 73.7748058 },
      map: this.map,
      title: 'VS Projects & System Engineers Pvt. Ltd.',
      animation: google.maps.Animation.DROP,
    });

    var contentString = '<div style="width:100%">' +
    '<p class="mb-0 text-center" style="font-weight:500;"><strong>VS Projects & System Engineers Pvt. Ltd.</strong></p>' +
    '<p class="mb-0 text-center" style="font-size:14px;">B-300, Lohia Jain Square, Bavdhan, <br/>' +
    ' Pune - 411 021 </p>' +
    '</div>';

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
      maxWidth: 340
    });

    marker.addListener('click', function () {
      infowindow.open(this.map, marker);
    });
    infowindow.open(this.map, marker);
    //this.map.setOptions(mapProp);
  }

  onSubmit() {
    this.showLoader = true;
    this.http.post('/sendmail/', this.contactForm).subscribe((res: any) => {
      if (res.status == 'success') {
        this.messageSuccess = true;
        this.showLoader = false;
      }
    }, err => {
      console.error(err);
      this.contactForm = {
        name: "",
        email: "",
        subject: "",
        message: "",
        recipient: "vspase"
      };
      this.messageSuccess = false;
      this.showLoader = false;
    });
  };


  ngOnInit() {
    this.initMap();
  }

}
