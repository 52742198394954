import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-links-strip',
  templateUrl: './social-links-strip.component.html',
  styleUrls: ['./social-links-strip.component.css']
})
export class SocialLinksStripComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input('socialStripBg') socialStripBg: string;

}
