import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
// import { BannerComponent } from '../../common/banner/banner.component';
// import { ContactStripComponent } from '../../common/contact-strip/contact-strip.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  componentBannerTitle = "About";
  titleSpan = "Us";
  componentBannerImageURL = "../../../assets/images/about-banner.jpg";
  componentBannerClasses = "page-banner banner-light";
  

  stripTitle = "Interested in our services now?";
  stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
  buttonTitle = "Contact Us";
  buttonLink = "/contact#contact-form";
  stripBg="bg-blue";

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'title', content: 'VS PASE | About Us' });
    this.meta.updateTag({ name: 'description', content: 'About VS PASE' });
    this.meta.updateTag({ name: 'author', content: 'VS PASE' });
    this.meta.updateTag({ name: 'keywords', content: 'META, Keyword, About' });
  }

  ngOnInit() {
  }

}
