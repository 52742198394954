import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta } from '@angular/platform-browser';

import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private http: HttpClient, private meta: Meta) {
    this.meta.addTag({ name: 'title', content: 'TEST META TITLE' });
    this.meta.addTag({ name: 'description', content: 'TEST META DESCRIPTION' });
    this.meta.addTag({ name: 'author', content: 'VS PASE' });
    this.meta.addTag({ name: 'keywords', content: 'META, Keyword, Angular, Test' });
  }

  ngOnInit() { }
}
