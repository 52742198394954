import { Component, OnInit } from '@angular/core';
import { CountToModule } from 'angular-count-to';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  componentBannerTitle = "Welcome to";
  titleSpan = "VS PASE";
  componentBannerImageURL = "../../assets/images/home-banner.jpg";
  componentBannerClasses = "page-banner banner-light";

  customer_count = {
    countTo: 350,
    from: 0,
    duration: 1
  };
  installation_count = {
    countTo: 472,
    from: 0,
    duration: 1
  };
  country_count = {
    countTo: 36,
    from: 0,
    duration: 1
  };
  continent_count = {
    countTo: 5,
    from: 0,
    duration: 1
  };

  // socialStripBg = "bg-dark";
  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'title', content: 'HOME' });
    this.meta.updateTag({ name: 'description', content: 'HOME DESCRIPTION' });
    this.meta.updateTag({ name: 'author', content: 'VS PASE' });
    this.meta.updateTag({ name: 'keywords', content: 'META, Keyword, Home' });
  }

  ngOnInit() {
  }

}
