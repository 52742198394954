import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spare-parts',
  templateUrl: './spare-parts.component.html',
  styleUrls: ['./spare-parts.component.css']
})
export class SparePartsComponent implements OnInit {

  componentBannerTitle = "Spares";
  componentBannerImageURL = "../../../assets/images/spares-banner.jpg";
  componentBannerClasses = "page-banner banner-light";

  stripTitle = "Interested in our services now?";
  stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
  buttonTitle = "Contact Us";
  buttonLink = "/contact#contact-form";
  stripBg = "bg-blue";

  socialStripBg = "bg-dark";

  constructor() { }

  ngOnInit() {
  }

}
