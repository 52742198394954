import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input('bannerTitle') title: string;
  @Input('titleSpan') titleSpan: string;
  @Input('bannerBackgroundImage') backgroundImage: string;
  @Input('bannerClass') bannerClass: string;
  
  

}
