import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dryers',
  templateUrl: './dryers.component.html',
  styleUrls: ['./dryers.component.css']
})
export class DryersComponent implements OnInit {

  componentBannerTitle = "Sugar Dryers";
  componentBannerImageURL = "";
  componentBannerClasses = "page-banner banner-light product-banner";

  stripTitle = "Interested in our services now?";
  stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
  buttonTitle = "Contact Us";
  buttonLink = "/contact#contact-form";
  stripBg = "bg-blue";
  
  socialStripBg = "bg-blue";


  constructor() { }

  ngOnInit() {
  }

}
