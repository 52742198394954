import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  componentBannerTitle = "Services";
  componentBannerImageURL = "../../assets/images/services-banner.jpg";
  componentBannerClasses = "page-banner banner-light";

  stripTitle = "Interested in our services now?";
  stripContent = "Leave us your contact details and our team shall get in touch with you at the earliest.";
  buttonTitle = "Contact Us";
  buttonLink = "/contact#contact-form";
  stripBg = "bg-dark";

  constructor() { }

  ngOnInit() {
  }

}
