import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  height: any;
  constructor() { }

  ngOnInit() {
    var element = document.querySelector('footer'); 
    this.height = element.offsetHeight;
  }

}
